import { DownloadOutlined } from '@ant-design/icons';
import { Button, Flex, Statistic, Table, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useOrganization, useOrganizationLedger } from '../../api/Onboarding';
import BackButton from '../../components/BackButton';
import Loading from '../../components/Loading';

const { Title } = Typography;

const StyledDiv = styled.div`
  padding: 0 24px 24px;
`;

const StyledStatistic = styled(Statistic)`
  padding-right: 24px;
`;

const StyledButton = styled(Button)`
  padding-left: 0;
`;

function Tokens() {
  const navigate = useNavigate();
  const { data: organization, isLoading: organizationLoading } = useOrganization();

  const { data: organizationLedger, isLoading: ledgerLoading } = useOrganizationLedger(
    {
      organizationId: organization?.data.id,
    },
    {
      enabled: !!organization?.data.id,
    },
  );

  // Function to handle exporting table data as CSV
  const handleCsvExport = () => {
    if (!organizationLedger?.data || organizationLedger.data.length === 0) return;

    // Format data for export
    const headers = ['Date', 'Transaction', 'Tokens', 'Balance', 'Description'];
    const data = organizationLedger.data.map((item) => [
      dayjs(item.created_at).format('MMM D, YYYY'),
      item.transaction_type,
      item.credits,
      item.balance,
      item.description,
    ]);

    // Prepare CSV content
    const csvContent = [headers.join(','), ...data.map((row) => row.join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.setAttribute('download', `fathom-token-usage-${dayjs().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const ledgerColumns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (date) => dayjs(date).format('MMM D, YYYY'),
    },
    {
      title: 'Transaction',
      dataIndex: 'transaction_type',
      sorter: (a, b) => a.transaction_type.localeCompare(b.transaction_type),
      render: (transactionType) => (
        <Tag color={transactionType === 'Debit' ? 'red' : 'green'}>{transactionType}</Tag>
      ),
    },
    {
      title: 'Tokens',
      dataIndex: 'tokens',
      sorter: (a, b) => a.credits - b.credits,
      render: (credits, record) => (
        <Typography.Text type={record.transaction_type === 'Debit' ? 'danger' : 'success'}>
          {credits}
        </Typography.Text>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      sorter: (a, b) => a.balance - b.balance,
      render: (balance) => <Typography.Text strong>{balance}</Typography.Text>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text, record) =>
        record.survey_id ? (
          <Link to={`/surveys/${record.survey_id}/portal`} target="_blank">
            {text}
          </Link>
        ) : (
          text
        ),
    },
  ];

  if (organizationLoading || ledgerLoading) {
    return <Loading />;
  }

  return (
    <StyledDiv>
      <BackButton onClick={() => navigate(-1)} text="Back" />
      <Flex vertical gap={16}>
        <Flex justify="space-between" align="end">
          <Flex vertical>
            <Title level={4}>Token Usage</Title>
            <StyledButton
              type="link"
              icon={<DownloadOutlined />}
              onClick={handleCsvExport}
              size="small"
            >
              Export CSV
            </StyledButton>
          </Flex>
          <Flex>
            <StyledStatistic title="Token balance" value={organization.data.available_tokens} />
          </Flex>
        </Flex>

        <Table
          dataSource={organizationLedger?.data}
          columns={ledgerColumns}
          rowKey="id"
          loading={ledgerLoading}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
            showTotal: (total) => `Total ${total} transactions`,
          }}
        />
      </Flex>
    </StyledDiv>
  );
}

export default Tokens;
