export const INTERCOM_APP_ID = 'mlhfh9u4';
export const INTERCOM_API_BASE = 'https://api-iam.intercom.io';

export const SurveyStatus = {
  PUBLISHED: 'Published',
  PUBLISH_IN_PROGRESS: 'Publish in Progress',
  UNPUBLISHED: 'Unpublished',
};

export const SurveyStatusText = {
  [SurveyStatus.PUBLISHED]: 'Analysis ready',
  [SurveyStatus.PUBLISH_IN_PROGRESS]: 'Coding complete',
  [SurveyStatus.UNPUBLISHED]: 'Coding in progress',
};

export const MATOMO_URL = 'https://avalancheinsights.matomo.cloud/';
export const EVENT_METRICS = 'Event Metrics';
export const MatomoEvent = {
  UPLOAD_SURVEY_BUTTON_CLICKS: 'upload-survey-button-click',
  OPEN_SURVEY_UPLOAD_FORM: 'open-survey-upload-form',
  EXPLORE_THEME_CLICKS: 'explore-theme-click',
  EXPLORE_SENTIMENT_CLICKS: 'explore-sentiment-click',
  MANAGE_THEMES_CLICKS: 'manage-themes-click',
  ADD_BUTTON_IN_SEGMENTS_CLICKS: 'add-button-segment-click',
  SEARCH_BAR_RESPONSE_CLICKS: 'search-bar-response-click',
  UPDATE_USER_ROLE: 'update-user-role',
  EDIT_THEME_SUMMARY: 'edit-theme-summary',
  EDIT_QUESTION_SUMMARY: 'edit-question-summary',
  EDIT_SURVEY_SUMMARY: 'edit-survey-summary',
};

export const QCPhase = {
  PROCESSING: 'Preprocessing Data',
  READY: 'Ready for Analysis',
  SELECT_THEMES: 'Select Themes',
  REMAPPING: 'Remapping in Progress',
  REMAPPING_COMPLETE: 'Remapping Complete',
  REVIEW: 'Review',
  COMPLETE: 'Analysis Completed',
};

export const QCPhaseText = {
  [QCPhase.PROCESSING]: 'Processing in progress',
  [QCPhase.READY]: 'Confirm code frame',
  [QCPhase.SELECT_THEMES]: 'Confirm code frame',
  [QCPhase.REMAPPING]: 'Mapping code frame',
  [QCPhase.REMAPPING_COMPLETE]: 'Mapping complete',
  [QCPhase.REVIEW]: 'Human review',
  [QCPhase.COMPLETE]: 'Coding complete',
};

export const UserRoleType = {
  ADMINISTRATOR: 'Administrator',
  EDITOR: 'Editor',
  VIEWER: 'Viewer',
  CLIENT: 'Client',
};

export const SegmentType = {
  ATTRIBUTE: 'Attribute',
  CUSTOM: 'Custom',
  ALL_RESPONDENTS: 'All Respondents',
};

export const SurveyDashboardTabs = {
  SUMMARY: 'Summary',
  THEMES: 'Themes',
  SENTIMENTS: 'Sentiments',
  RESPONSES: 'Responses',
};

export const SurveyListTabs = {
  SURVEYS: 'Surveys',
  ARCHIVE: 'Archive',
};

export const QuestionTypeValues = {
  REMOVE: 0,
  HAS_THEMES: 1,
  IS_SINGLE_SELECT: 2,
  IS_KEY: 3,
  IS_METADATA: 4,
  IS_MULTI_SELECT: 5,
};

export const SurveyTypes = [
  'Pulse',
  'Engagement',
  'Exit',
  'Culture',
  'Wellness',
  'Onboarding',
  'Customer Feedback',
  'Other',
];

export const SurveyTools = [
  'Survey Monkey',
  'Qualtrics',
  'Google Forms',
  'Microsoft Forms',
  'Internal Data',
  'Other',
];

export const SettingsPageTabs = {
  USERS: 'Users',
  INVITATIONS: 'Invitations',
};

export const SURVEY_ID_HEADER = 'Requires-Survey-ID';

export const ChartValueType = {
  PERCENTAGE: 'percentage',
  COUNT: 'count',
};

export const SUMMARY_COLLAPSE_KEY = 'summary';

export const ExportOption = {
  ALL: 'all',
  THEMES: 'themes',
  CUSTOM: 'custom',
};

export const ThemeEnginePanel = {
  MY_CODE_FRAME: 'my-code-frame',
  EXPLORE_THEMES: 'explore-themes',
  EXPLORE_RAW_DATA: 'explore-raw-data',
};

export const ResponseSortOptions = {
  UNSORTED: 'Unsorted',
  RANDOM: 'Random',
  ALPHABETICAL: 'Alphabetical',
};

export const ResponseFilterOptions = {
  ALL: 'All',
  UNTAGGED: 'Untagged',
  ONE_THEME: '1+ theme',
  TWO_THEMES: '2+ themes',
  THREE_THEMES: '3+ themes',
  FOUR_THEMES: '4+ themes',
};

export const ThemeSortOptions = {
  SUGGESTED_GROUPS: 'Suggested groups',
  SIZE: 'Size',
  UNSORTED: 'Unsorted',
};

export const QueryKeys = {
  AGGREGATE_RESPONSES: 'aggregateResponses',
  CODE_FRAME: 'codeFrame',
  CODE_FRAME_RESPONSES: 'codeFrameResponses',
  CODE_FRAME_THEMES: 'codeFrameThemes',
  INVITATIONS: 'invitations',
  ORGANIZATION: 'organization',
  ORGANIZATION_LEDGER: 'organizationLedger',
  ORGANIZATION_SURVEYS: 'organizationSurveys',
  ORGANIZATIONS: 'organizations',
  PERSISTED_SEGMENT: 'persistedSegment',
  PERSISTED_SEGMENTS: 'persistedSegments',
  QUESTION: 'question',
  QUESTION_RESPONSES: 'questionResponses',
  QUESTION_SUMMARY: 'questionSummary',
  QUESTIONS: 'questions',
  RESPONSE_OPTIONS: 'responseOptions',
  SAVED_SEGMENTS: 'savedSegments',
  SEGMENT_SUMMARY: 'segmentSummary',
  SEGMENT_SUMMARY_THEME: 'segmentSummaryTheme',
  SENTIMENTS: 'sentiments',
  SUGGESTED_GROUPS: 'suggestedGroups',
  SUGGESTED_THEMES: 'suggestedThemes',
  SURVEY: 'survey',
  SURVEY_CLIENTS: 'surveyClients',
  SURVEY_INVITATIONS: 'surveyInvitations',
  SURVEY_SUMMARY: 'surveySummary',
  THEMES: 'themes',
  THEME_COVERAGE: 'themeCoverage',
  THEME_SUMMARY: 'themeSummary',
  USERS: 'users',
};

export const FilterSortType = {
  FILTER: 'Filter',
  SORT: 'Sort',
};

export const AccountStatus = {
  ACTIVE: 'Active',
  TRIAL: 'Trial',
  SUSPENDED: 'Suspended',
  CHURNED: 'Churned',
};

export const OrganizationType = {
  SELF_SERVE: 'Self serve',
  MAGIC_BOX: 'Magic box',
};

export const TESelectionType = {
  SUGGESTED_THEME: 'suggestedTheme',
  THEME: 'theme',
  GROUP: 'group',
  SENTIMENT: 'sentiment',
};

export const PERCENTAGE_RANGES = [
  { max: 5, label: '0-5' },
  { max: 20, label: '5-20' },
  { max: 100, label: '20-100' },
];
