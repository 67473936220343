import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Flex, Popconfirm, Space, Typography, message } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useCreateUpdateOrganization, useOrganizations } from '../../api/Onboarding';
import Loading from '../../components/Loading';
import SearchableTable from '../../components/SearchableTable';
import { AccountStatus, OrganizationType } from '../../constants';
import TokensModal from './TokensModal';
import UploadsModal from './UploadsModal';
import UsersModal from './UsersModal';

const { Title } = Typography;

const StyledFlex = styled(Flex)`
  padding: 0 24px 24px;
`;

const StyledButton = styled(Button)`
  padding-left: 0;
`;

function AdminPanel() {
  const [tokenModalVisible, setTokenModalVisible] = useState(false);
  const [usersModalVisible, setUsersModalVisible] = useState(false);
  const [uploadsModalVisible, setUploadsModalVisible] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [pendingStatusChange, setPendingStatusChange] = useState(null);
  const [pendingTypeChange, setPendingTypeChange] = useState(null);

  const { data: organizations, isLoading: organizationsLoading } = useOrganizations();
  const { mutate: updateOrganization, isLoading: updateOrganizationLoading } =
    useCreateUpdateOrganization();

  if (window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }

  // Handler for changing organization type
  const handleUpdateType = ({ id, type }) => {
    updateOrganization(
      {
        data: {
          id,
          self_serve_enabled: type === OrganizationType.SELF_SERVE,
        },
      },
      {
        onSuccess: () => {
          message.success('Organization type updated successfully');
        },
        onSettled: () => {
          setPendingTypeChange(null);
        },
      },
    );
  };

  // Handler for changing account status
  const handleUpdateStatus = ({ id, status }) => {
    updateOrganization(
      {
        data: {
          id,
          account_status: status,
        },
      },
      {
        onSuccess: () => {
          message.success('Account status updated successfully');
        },
        onSettled: () => {
          setPendingStatusChange(null);
        },
      },
    );
  };

  // Handler for opening token modal
  const showTokenModal = (org) => {
    setSelectedOrg(org);
    setTokenModalVisible(true);
  };

  // Handler for opening users modal - simplified
  const showUsersModal = (org) => {
    setSelectedOrg(org);
    setUsersModalVisible(true);
  };

  // Handler for opening uploads modal
  const showUploadsModal = (org) => {
    setSelectedOrg(org);
    setUploadsModalVisible(true);
  };

  // Define type options for dropdown
  const typeItems = [
    {
      key: OrganizationType.SELF_SERVE,
      label: OrganizationType.SELF_SERVE,
    },
    {
      key: OrganizationType.MAGIC_BOX,
      label: OrganizationType.MAGIC_BOX,
    },
  ];

  // Define status options for dropdown
  const statusItems = Object.values(AccountStatus).map((status) => ({
    key: status,
    label: status,
  }));

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Organization Type',
      dataIndex: 'self_serve_enabled',
      render: (selfServeEnabled, record) => (
        <>
          <Dropdown
            menu={{
              items: typeItems,
              onClick: (item) => {
                const currentType = selfServeEnabled
                  ? OrganizationType.SELF_SERVE
                  : OrganizationType.MAGIC_BOX;
                if (item.key !== currentType) {
                  setPendingTypeChange({
                    id: record.id,
                    currentType,
                    newType: item.key,
                  });
                }
              },
            }}
          >
            <StyledButton type="link">
              <Space>
                {selfServeEnabled ? OrganizationType.SELF_SERVE : OrganizationType.MAGIC_BOX}
                <DownOutlined />
              </Space>
            </StyledButton>
          </Dropdown>

          {pendingTypeChange && pendingTypeChange.id === record.id && (
            <Popconfirm
              title="Change Organization Type"
              description={`Are you sure you want to change type from ${pendingTypeChange.currentType} to ${pendingTypeChange.newType}?`}
              open
              onConfirm={() => handleUpdateType({ id: record.id, type: pendingTypeChange.newType })}
              onCancel={() => setPendingTypeChange(null)}
              okText="Save"
              cancelText="Cancel"
            />
          )}
        </>
      ),
    },
    {
      title: 'Account Status',
      dataIndex: 'account_status',
      render: (status, record) => (
        <>
          <Dropdown
            menu={{
              items: statusItems,
              onClick: (item) => {
                if (item.key !== status) {
                  setPendingStatusChange({
                    id: record.id,
                    currentStatus: status,
                    newStatus: item.key,
                  });
                }
              },
            }}
          >
            <StyledButton type="link">
              <Space>
                {status}
                <DownOutlined />
              </Space>
            </StyledButton>
          </Dropdown>

          {pendingStatusChange && pendingStatusChange.id === record.id && (
            <Popconfirm
              title="Change Account Status"
              description={`Are you sure you want to change status from ${pendingStatusChange.currentStatus} to ${pendingStatusChange.newStatus}?`}
              open
              onConfirm={() =>
                handleUpdateStatus({ id: record.id, status: pendingStatusChange.newStatus })
              }
              onCancel={() => setPendingStatusChange(null)}
              okText="Save"
              cancelText="Cancel"
            />
          )}
        </>
      ),
    },
    {
      title: 'Available Tokens',
      dataIndex: 'available_tokens',
      sorter: (a, b) => a.available_tokens - b.available_tokens,
      render: (tokens, record) => (
        <StyledButton type="link" onClick={() => showTokenModal(record)}>
          {tokens}
        </StyledButton>
      ),
    },
    {
      title: 'Total Users',
      dataIndex: 'total_users',
      sorter: (a, b) => (a.total_users || 0) - (b.total_users || 0),
      render: (totalUsers, record) => (
        <StyledButton type="link" onClick={() => showUsersModal(record)}>
          {totalUsers || 0}
        </StyledButton>
      ),
    },
    {
      title: 'Total Uploads',
      dataIndex: 'total_uploads',
      sorter: (a, b) => (a.total_uploads || 0) - (b.total_uploads || 0),
      render: (totalUploads, record) => (
        <StyledButton type="link" onClick={() => showUploadsModal(record)}>
          {totalUploads || 0}
        </StyledButton>
      ),
    },
  ];

  if (organizationsLoading) {
    return <Loading />;
  }

  return (
    <StyledFlex vertical gap={12}>
      <Title level={2}>Admin Panel</Title>

      <SearchableTable
        columns={columns}
        baseData={organizations?.data || []}
        rowKey="id"
        searchPlaceholder="Search organizations..."
        loading={updateOrganizationLoading}
        searchColumns={['name']}
        pagination={{
          pageSize: 10,
          showSizeChanger: false,
          showTotal: (total) => `Total ${total} organizations`,
        }}
      />

      <TokensModal
        visible={tokenModalVisible}
        onCancel={() => setTokenModalVisible(false)}
        organization={selectedOrg}
      />

      <UsersModal
        visible={usersModalVisible}
        onCancel={() => setUsersModalVisible(false)}
        organization={selectedOrg}
      />

      <UploadsModal
        visible={uploadsModalVisible}
        onCancel={() => setUploadsModalVisible(false)}
        organization={selectedOrg}
      />
    </StyledFlex>
  );
}

export default AdminPanel;
