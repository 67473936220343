import { Collapse, Flex, Typography } from 'antd';
import he from 'he';
import React, { useCallback, useEffect, useState } from 'react';
import ReactFC from 'react-fusioncharts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useThemeSummary } from '../../api/CodeFrames';
import { useQuestion, useQuestionResponses } from '../../api/Questions';
import { usePersistedSegment } from '../../api/Surveys';
import { useThemes } from '../../api/Themes';
import BackButton from '../../components/BackButton';
import Loading from '../../components/Loading';
import SearchableTable from '../../components/SearchableTable';
import { getThemeSentimentBarChart } from '../../fusion-charts/ChartHelper';
import useParams from '../../hooks/useParams';
import ThemeSummary from './ThemeSummary';

const { Text, Title } = Typography;

const StyledDiv = styled.div`
  padding: 0 24px 24px;
`;

const StyledCollapse = styled(Collapse)`
  background: #ffffff;
  border: 1px solid #f0f0f0;

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-item {
    border: none;
  }
`;

const StyledSearchableTable = styled(SearchableTable)`
  .ant-table-tbody > tr > td {
    border: none;
  }
`;

const StyledResponseText = styled(Text)`
  margin-right: 8px;
`;

function ResponseExplorer() {
  const navigate = useNavigate();
  const { questionId, themeId, surveyId } = useParams();
  const [searchParams] = useSearchParams();

  const [searchFilters, setSearchFilters] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [positiveCount, setPositiveCount] = useState(0);
  const [negativeCount, setNegativeCount] = useState(0);
  const [neutralCount, setNeutralCount] = useState(0);

  const setPercentages = (responses) => {
    setPositiveCount(
      responses.reduce((acc, cur) => (cur.sentiment === 'positive' ? acc + 1 : acc), 0),
    );

    setNegativeCount(
      responses.reduce((acc, cur) => (cur.sentiment === 'negative' ? acc + 1 : acc), 0),
    );

    setNeutralCount(
      responses.reduce((acc, cur) => (cur.sentiment === 'neutral' ? acc + 1 : acc), 0),
    );
  };

  const { data: responses, isLoading: responsesLoading } = useQuestionResponses(
    { questionId, filters: searchFilters, surveyId },
    {
      enabled: !!searchFilters,
      onSuccess: (data) => setPercentages(data.data),
    },
  );

  const { data: theme, isLoading: themeLoading } = useThemes(
    { themeIds: [themeId] },
    { select: (data) => data.data[0] },
  );

  const { data: question, isLoading: questionLoading } = useQuestion({ surveyId, questionId });

  const { data: summary, isLoading: summaryLoading } = useThemeSummary(
    { surveyId, codeFrameId: question?.data.code_frame_id, themeId },
    { enabled: !!question },
  );

  const { data: segment, isFetching: segmentFetching } = usePersistedSegment(
    { surveyId, segmentId: searchParams.get('segmentId') },
    { enabled: !!searchParams.get('segmentId') },
  );

  useEffect(() => {
    // Filter options sometimes contain special characters like `+` and `&`
    // In order to link to this page and preserve those characters, those params are encoded as `_$PLUS_` and `_$AMPERSAND_`
    const decodedUrlSearchParams = new URLSearchParams({ theme_id: themeId, with_theme_ids: true });
    searchParams.forEach((value, key) => {
      if (key !== 'segmentId') {
        decodedUrlSearchParams.append(
          key,
          value.replaceAll('_$PLUS_', '+').replaceAll('_$AMPERSAND_', '&'),
        );
      }
    });
    setSearchFilters(decodedUrlSearchParams);
  }, [searchParams, themeId]);

  // whenever page changes, scroll back to top
  useEffect(() => {
    window.scroll(0, 0);
  }, [page]);

  const afterSearch = useCallback(
    (searchTerm) => {
      setPage(1);
      const filteredData = responses.data.filter((r) =>
        r.text.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setPercentages(filteredData);
    },
    [setPage, responses?.data],
  );

  const columns = [
    {
      dataIndex: 'text',
      render: (text) => <StyledResponseText italic>{`"${he.decode(text)}"`}</StyledResponseText>,
    },
  ];

  const handlePageChange = (newPage, newPageSize) => {
    // if changing page size, return to page 1
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
      setPage(1);
    } else {
      setPage(newPage);
    }
  };

  const sentimentChart = [
    {
      key: 'sentiment',
      label: <Text strong>Sentiment Distribution</Text>,
      children: (
        <ReactFC
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getThemeSentimentBarChart({
            positiveCount,
            negativeCount,
            neutralCount,
          })}
        />
      ),
    },
  ];

  if (themeLoading || responsesLoading || questionLoading || summaryLoading || segmentFetching) {
    return <Loading />;
  }

  return (
    <StyledDiv>
      <BackButton
        onClick={() => navigate(`/surveys/${surveyId}?questionId=${questionId}`)}
        text="Back to themes"
      />
      <Flex vertical gap={12}>
        <Flex align="baseline" gap={16}>
          <Title level={4}>{`Individual responses that contain the theme ${theme.name}`}</Title>
          <Text type="secondary">{segment?.data.label}</Text>
        </Flex>
        {!searchParams.get('segmentId') && summary?.data.summary && (
          <ThemeSummary themeId={themeId} question={question.data} summary={summary.data} />
        )}
        {question.data.display_sentiment && (
          <StyledCollapse collapsible="icon" items={sentimentChart} />
        )}
        <StyledSearchableTable
          searchPlaceholder="Search responses..."
          showHeader={false}
          pagination={{
            defaultPageSize: 50,
            onChange: handlePageChange,
            current: page,
            pageSize,
            showTotal: (total) => `Total ${total} responses`,
          }}
          baseData={responses.data}
          columns={columns}
          rowKey="id"
          searchColumns={['text']}
          afterSearch={afterSearch}
        />
      </Flex>
    </StyledDiv>
  );
}

export default ResponseExplorer;
