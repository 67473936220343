import { DownloadOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Table,
  Tag,
  Typography,
  message,
} from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAddTokens, useDeductTokens, useOrganizationLedger } from '../../api/Onboarding';

const { Title } = Typography;

const StyledButton = styled(Button)`
  padding-left: 0;
`;

function TokensModal({ visible, onCancel, organization = null }) {
  const [form] = Form.useForm();
  const currTokens = Form.useWatch('tokens', form);

  const { mutate: addTokens, isLoading: addTokensLoading } = useAddTokens();
  const { mutate: deductTokens, isLoading: deductTokensLoading } = useDeductTokens();

  // Reset form when organization or visibility changes
  useEffect(() => {
    if (visible && organization) {
      form.setFieldsValue({
        tokenOperation: 'add',
        tokens: 0,
        description: '',
        currentTokens: organization.available_tokens,
      });
    }
  }, [visible, organization, form]);

  const {
    data: organizationLedger,
    isLoading,
    refetch,
  } = useOrganizationLedger(
    {
      organizationId: organization?.id,
    },
    {
      enabled: !!organization && visible,
    },
  );

  // Handler for token operations
  const handleTokenOperation = async () => {
    const values = await form.validateFields();
    const { tokenOperation, tokens, description } = values;

    if (tokens === 0) {
      onCancel();
      return;
    }

    const tokenMutation = tokenOperation === 'add' ? addTokens : deductTokens;
    tokenMutation(
      {
        organizationId: organization.id,
        data: { tokens, description },
      },
      {
        onSuccess: () => {
          message.success(`Tokens ${tokenOperation === 'add' ? 'added' : 'deducted'} successfully`);
          refetch();
          onCancel();
        },
      },
    );
  };

  // Define columns for ledger table
  const ledgerColumns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (date) => dayjs(date).format('MMM D, YYYY'),
    },
    {
      title: 'Transaction',
      dataIndex: 'transaction_type',
      sorter: (a, b) => a.transaction_type.localeCompare(b.transaction_type),
      render: (transactionType) => (
        <Tag color={transactionType === 'Debit' ? 'red' : 'green'}>{transactionType}</Tag>
      ),
    },
    {
      title: 'Tokens',
      dataIndex: 'credits',
      sorter: (a, b) => a.credits - b.credits,
      render: (credits, record) => (
        <Typography.Text type={record.transaction_type === 'Debit' ? 'danger' : 'success'}>
          {credits}
        </Typography.Text>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      sorter: (a, b) => a.balance - b.balance,
      render: (balance) => <Typography.Text strong>{balance}</Typography.Text>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text, record) =>
        record.survey_id ? (
          <Link to={`/surveys/${record.survey_id}/hub`} target="_blank">
            {text}
          </Link>
        ) : (
          text
        ),
    },
  ];

  // Function to handle exporting table data as CSV
  const handleCsvExport = () => {
    if (!organizationLedger?.data || organizationLedger.data.length === 0) return;

    // Format data for export
    const headers = ['Date', 'Transaction', 'Tokens', 'Balance', 'Description'];
    const data = organizationLedger.data.map((item) => [
      dayjs(item.created_at).format('MMM D, YYYY'),
      item.transaction_type,
      item.credits,
      item.balance,
      item.description,
    ]);

    // Prepare CSV content
    const csvContent = [headers.join(','), ...data.map((row) => row.join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.setAttribute('download', `fathom-token-usage-${dayjs().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      title={`Manage Tokens - ${organization?.name}`}
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={1500}
      style={{ top: '5%' }}
    >
      <Form form={form} layout="inline" style={{ marginBottom: 16 }}>
        <Form.Item label="Current Tokens" style={{ marginBottom: 8 }}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {organization?.available_tokens || 0}
          </Typography.Text>
        </Form.Item>

        <Form.Item name="tokens" label="Add or remove tokens" style={{ marginBottom: 8 }}>
          <InputNumber
            addonBefore={
              <Form.Item name="tokenOperation" noStyle>
                <Select
                  options={[
                    { value: 'add', label: '+' },
                    { value: 'minus', label: '-' },
                  ]}
                />
              </Form.Item>
            }
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          style={{ marginBottom: 8, flexGrow: 1 }}
          rules={[
            {
              required: currTokens > 0,
              message: 'Description is required when adding or removing tokens',
            },
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item style={{ marginBottom: 8 }}>
          <Button
            type="primary"
            loading={addTokensLoading || deductTokensLoading}
            onClick={handleTokenOperation}
            style={{ marginRight: 8 }}
          >
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Form.Item>
      </Form>

      <Divider />
      <Flex justify="space-between" align="center">
        <Title level={4} style={{ margin: 0 }}>
          Token Usage
        </Title>
        <StyledButton
          type="link"
          icon={<DownloadOutlined />}
          onClick={handleCsvExport}
          size="small"
        >
          Export CSV
        </StyledButton>
      </Flex>
      <Table
        dataSource={organizationLedger?.data}
        columns={ledgerColumns}
        rowKey="id"
        loading={isLoading}
        pagination={{
          pageSize: 10,
          showSizeChanger: false,
          showTotal: (total) => `Total ${total} transactions`,
        }}
      />
    </Modal>
  );
}

TokensModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    available_tokens: PropTypes.number,
  }),
};

export default TokensModal;
